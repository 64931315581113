html {
  font-size: 100%;
}
body {
  background-color: #f0f2f5;
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial, sans-serif;
  line-height: 1.5;
}
button:disabled {
  opacity: 0.5;
}
.Login {
  margin-top: 10%;
}
.Login__title {
  margin: 0 0 1.5rem 0;
  text-align: center;
}
.Login__button-row {
  text-align: center;
}
.Login__login-button {
  width: 50%;
}
.AnimatedTable__cell:hover {
  cursor: pointer;
}
.AnimatedTable__control-buttons {
  font-size: 1.2em;
}
.Filters__row {
  margin: 1rem 0 0 0;
}
.Filters__col {
  margin: 0 0 1rem 0;
}
.Search__input {
  margin-top: 0.5rem;
  margin-bottom: 1.5rem;
}
.Card__tabs {
  margin-top: 0.5rem;
}
.Header__userInfo {
  color: #fff;
  float: right;
}
.Header__exit-link {
  margin: 0 0 0 15px;
}
.Header__user-link {
  color: #fff;
}
@media screen and (max-width: 1024px) {
.Header {
  padding: 0 10px;
}
}
.Content {
  padding: 25px 50px 0 50px;
@media screen and (max-width: 1024px) {
}
}
.Content__container {
  background-color: #fff;
  padding: 24px;
  min-height: 280px;
}
@media screen and (max-width: 1024px) {
.Content {
  padding: 0;
}
.Content__container {
  padding: 15px 10px;
}
}
.Footer {
  text-align: center;
}
.Tab__label-title {
  margin-bottom: 0.15rem;
}
.Tab__label-title-required::after {
  display: inline-block;
  margin-right: 4px;
  color: #f5222d;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: "*";
}
.Tab__label {
  margin-bottom: 1rem;
}
.Table__loader {
  margin: 2rem 0;
  text-align: center;
}
.Table__disabled-row {
  opacity: 0.5;
  pointer-events: none;
  cursor: wait;
}
.Table__read-cell:hover {
  cursor: pointer;
}
.DynamicSelect__template {
  margin-bottom: 15px;
}
.DynamicSelect__template-row {
  border-bottom: 1px solid #d9d9d9;
  margin-bottom: 5px;
  padding: 5px 0;
  font-size: 13px;
}
.ImageField__modal-wrapper {
  position: relative;
}
.ImageField__modal-image {
  width: 100%;
}
.ImageField__modal-thumbnail {
  width: 150px;
  height: 150px;
  position: absolute;
  right: 0;
  bottom: 0;
  border-left: 5px #fff solid;
  border-top: 5px #fff solid;
}
